import React from 'react'

import styles from './hero.module.css'

export default () => (
  <div className={styles.presentationBox}>
    <div className={styles.presentation}>
      <h2>About Me</h2>
      <div className={styles.img}>
        <img 
          alt='me'
          src="m3.jpg"
        />
      </div>
      Software engineering and business management, currently working as a business and management consultant in technology at Publicis Groupe. I value openness and creativity, and I enjoy working in environments that give people the opportunity to bloom and find themselves.
    </div>
  </div>
)
