import React from 'react'
import { graphql, withAssetPrefix } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Navigation from '../components/navigation'
import MobileNavigation from '../components/mobile-menu'
import ArticlePreview from '../components/article-preview'
import styles from './index.module.css'
import initOcean from '../3D/ocean';

class RootIndex extends React.Component {
  componentDidMount() {
    if(typeof window !== `undefined`) {
      
      /* eslint-disable */
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = 'X6AZX';
      window['_fs_namespace'] = 'FS';
      (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.2.0";
      })(window,document,window['_fs_namespace'],'script','user');

      /* eslint-enable */

      initOcean();
      document.getElementById('scrollAnchor').addEventListener("scroll", (e) => {
        const a = document.getElementById("anchor");
        const p = document.getElementById("paralax");
        var rect = a.getBoundingClientRect();
  
        if (rect.top < window.innerHeight && rect.top > window.innerHeight / 2 && (window.innerWidth > 200 && rect.top > window.innerHeight - window.innerWidth / 2.2)) {
          p.style.top = ((rect.top - window.innerHeight) * 0.7) + "px";
        }
      });
    }
  }

  render() {
    const posts = [
      {
        title: 'JavaScript, ES6, ES7, ES10 where are we?',
        date: 'December 2nd, 2019',
        body: 'What are the current features and proposals, and what have been withdrawn/rejected?',
        url: 'https://medium.com/engineered-publicis-sapient/javascript-es6-es7-es10-where-are-we-8ac044dfd964',
        thumbnail: 'tb1.gif'
      },
      {
        title: 'Electron-CSS v1.0.2',
        date: 'November 10th, 2019',
        body: 'Clean and flexible framework agnostic Style in JS, without any of the fuss of CSS and JSX. New version with bundle size reduced by 50%',
        url: 'https://github.com/azukaar/electron-css',
        thumbnail: 'tb2.jpg'
      },
      {
        title: 'GuPm v1.2',
        date: 'August 10th, 2019',
        body: 'Global Universal Project Manager -- Package manager, cli tool, scripts for all your projects and your system. Now with global installations and Brew support.',
        url: 'https://github.com/azukaar/gupm',
        thumbnail: 'tb3.jpg'
      },
      {
        title: 'Typescript in the real world — Why and how you should use it',
        date: 'August 6th, 2019',
        body: 'Typescript is an exciting technology gaining a lot of tractions. Find out why and how you should start using it, with this article written to ease out your learning curve.',
        url: 'https://medium.com/engineered-publicis-sapient/typescript-in-the-real-world-132d32a290db',
        thumbnail: 'tb4.gif'
      },
    ]
    

    return (
      <div id="scrollAnchor" style={{position:'absolute', overflow:'auto', top:0, bottom: 0, left: 0, right: 0}}>
        <MobileNavigation />
        <div id="scrollAnchor2" style={{minHeight: '100%',backgroundColor:'white'}}>
          <Navigation />
          <Helmet title={'Yann Stepienik'}>
            <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
          </Helmet>
          <Hero />
          <div id="overwater" className={styles.overwater}></div>

          <div className={styles.videolimittop}>
            <div></div>
          </div>

          <div className={styles.quoteBox} style={{clear: "both"}}>
            <div className={styles.quote}>
              “To develop a complete mind: Study the science of art; Study the art of science. Learn how to see. Realize that
              everything connects to everything else.”
              <br />
              ― Leonardo da Vinci
            </div>
          </div>

          <video style={{minHeight: "350px"}} width="100%" className={styles.underwater} autoPlay loop muted playsInline>
            <source src="underwater.mov" />
          </video>

          <div className={styles.videolimit}>
            <div></div>
          </div>
        
          <div id="anchor" className={styles.anchor}></div>

          <div id="paralax" className={styles.paralax}>
            <div className={styles.transition}>
              <div className={styles.gradient3}>
                <div className={styles.gradient}>
                  <div className={styles.gradient2}></div>
                </div>
              </div>
            </div>

            <div className={styles.mainContent}>
              <h1>Latest publications</h1>
              <ul className={styles.pubPreview}>
              {posts.map((node) => {
                return (
                  <li key={node.url}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
              </ul>
              <div className={styles.pubPreviewSplit}></div>
              <h1>Projects and philosophy</h1>
              <img src="/p1.jpg" className={styles.sidePic} />
              <div className={styles.textBox}>
              After a decade working to materialize ideas into products, and shape visions into plans, I am  always looking forward tackling the next challenge in my career and my life, always inspiring to improve, and bring the people around with me on the journey.<br/><br/>
More than ever at Publicis Groupe / Publicis Sapient, I have been exposed to the need to embrace this way of thinking, since my role here has primarily consisted of team management and mentoring,architecture and shaping a plan for the future of our client (Debenhams). Strong decision-making skills, communication, and consistency are key skills that have been put to the test for the past 2 years, with success so far.
              </div>
              <h1>Get in touch! 📧</h1>
              <div className={styles.textBox}>
              If you are interested in getting in touch, please use the contact page, or my contact information in my CV (see in the menu). Thanks for your interest, looking forward to hearing from you!
              </div>
              <div className={styles.pubPreviewSplit}></div>
              < hr />
              <div className={styles.pubPreviewSplit}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RootIndex
