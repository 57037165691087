import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => {
  return(
  <div className={styles.preview}>
    <Link target="_blank" to={`${article.url}`} className={styles.link}>
    <img width="100%" alt={article.title} src={article.thumbnail} />
    <h3 className={styles.previewTitle}>
      {article.title}
    </h3>
    <small>{article.date}</small>
    <div className={styles.body}
      dangerouslySetInnerHTML={{
        __html: article.body,
      }}
    />
    </Link>
  </div>
)}
